<div class="container" style="margin-top: 7rem; margin-bottom: 10rem;">
  <h3>Lawyer Appointments</h3>
  <hr>
  <!-- <div class="row mb-3">
    <div class="col-md-3">
      <label for="statusSelect">Filter by Status</label>
      <select id="statusSelect" class="form-select" [(ngModel)]="selectedStatus" (ngModelChange)="onChangeStatus($event)">
        <option value="">Select Status</option>
        <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
      </select>
    </div>
    <div class="col-md-3">
      <label for="fromDate">From Date</label>
      <input type="date" id="fromDate" class="form-control" [(ngModel)]="fromDate">
    </div>
    <div class="col-md-3">
      <label for="toDate">To Date</label>
      <input type="date" id="toDate" class="form-control" [(ngModel)]="toDate">
    </div>
    <div class="col-md-3">
      <label for="searchText">Filter Text</label>
      <input type="text" id="searchText" class="form-control" placeholder="Enter" [(ngModel)]="searchText">
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-3">
      <label for="pageSizeSelect">Items per Page</label>
      <select id="pageSizeSelect" class="form-select" [(ngModel)]="pageSize" (ngModelChange)="onPageSizeChange()">
        <option *ngFor="let size of pageSizes" [value]="size">{{ size }} per page</option>
      </select>
    </div>
    <div class="col-md-3">
      <button class="btn lufz-btn_no_hover mt-4" (click)="filterAppointments()">Filter</button>
    </div>
  </div> -->


  <form [formGroup]="form"> <!-- FormGroup binding -->
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="statusSelect">Filter by Status</label>
        <select id="statusSelect" class="form-select" formControlName="selectedStatus">
          <option value="0" selected>Select Status</option>
          <option *ngFor="let status of statuses" [value]="status.id">{{ status.text }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="fromDate">From Date</label>
        <input type="date" id="fromDate" class="form-control" formControlName="fromDate">
      </div>
      <div class="col-md-3">
        <label for="toDate">To Date</label>
        <input type="date" id="toDate" class="form-control" formControlName="toDate">
      </div>
      <div class="col-md-3">
        <label for="searchText">Filter Text</label>
        <input type="text" id="searchText" class="form-control" formControlName="searchText"
          (ngModelChange)="onSearchText($event)">
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="pageSizeSelect">Items per Page</label>
        <select id="pageSizeSelect" class="form-select" formControlName="pageSize" (change)="onPageSizeChange()">
          <option *ngFor="let size of pageSizes" [value]="size">{{ size }} per page</option>
        </select>
      </div>
      <div class="col-md-3">
        <button class="btn lufz-btn_no_hover mt-4" style="padding-top: 7px !important; padding-bottom: 7px !important;"
          (click)="filterAppointments()">Filter</button>
      </div>
    </div>
  </form>
  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
        <tr>
          <th class="p-2" (click)="sortAppointments('appointmentNo')">Appointment No</th>
          <th class="p-2" (click)="sortAppointments('customerFirstName')">Customer Name</th>
          <th class="p-2" (click)="sortAppointments('appointmentDate')">Date</th>
          <th class="p-2" (click)="sortAppointments('startTime')">Start Time</th>
          <th class="p-2" (click)="sortAppointments('endTime')">End Time</th>
          <th class="p-2" (click)="sortAppointments('status')">Status</th>
          <!-- <th class="p-2">Actions</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let appointment of dataSource">
          <td>{{ appointment.appointmentNo }}</td>
          <td>{{ appointment.customerFirstName }} {{ appointment.customerLastName }}</td>
          <td>{{ appointment.appointmentDate | date }}</td>
          <td>{{ appointment.startTime | timeFormat }}</td>
          <td>{{ appointment.endTime | timeFormat }}</td>
          <td>
            <!-- <span [ngStyle]="{'color': getStatusColor(appointment.status)}">{{ appointment.status }}</span> -->
            <select class="form-select" (change)="onStatusChange(appointment.lawyerAppointmentId, $event.target.value)">
              <option *ngFor="let status of statuses" [value]="status.id" [selected]="appointment.statusId === status.id">
                {{ status.text }}
              </option>
            </select>
          </td>
          <!-- <td style="text-align: center;">
            <button class="btn lufz-btn_no_hover" style="padding-top: 7px !important; padding-bottom: 7px !important;"
              (click)="submitStatusChange()">Update</button>
          </td> -->
        </tr>
        <tr *ngIf="!dataSource || dataSource.length === 0">
          <td colspan="6" class="text-center">No data found</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Pagination -->
  <nav *ngIf="totalItems > 0" aria-label="Page navigation">
    <ul class="pagination justify-content-end">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link" (click)="previousPage()" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item" [class.active]="page === currentPage" *ngFor="let page of getPages()">
        <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="nextPage()" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>

  <div *ngIf="loading" class="text-center">
    <p>Loading more appointments...</p>
  </div>
</div>


<!-- a modal for message input -->
<!-- <div class="modal" tabindex="-1" role="dialog" id="statusChangeModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Enter a Message for Status Change</h5>
      </div>
      <div class="modal-body">
        <textarea class="form-control" [(ngModel)]="message" placeholder="Enter message"></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="submitStatusChange()">Submit</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->