import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-attorney',
  templateUrl: './attorney.component.html',
  styleUrls: ['./attorney.component.scss']
})
export class AttorneyComponent implements OnInit {
  isLoggedIn: boolean = false;
  userMasterId: string | null = null;
  constructor(private service: MasterService, private loaderService: LoaderService) { 
    this.checkLoginStatus();
  }
  
  checkLoginStatus(): void {
    this.userMasterId = localStorage.getItem('userMasterId');
    this.isLoggedIn = !!this.userMasterId;
  }

  ngOnInit(): void {
    if(this.isLoggedIn){
      this.getLawyers();
    }
  }

  getLawyers(){
    this.loaderService.show();
    this.service.get(`lawyer/${this.userMasterId}/all`).subscribe({
      next: (response) => {
        
      }, error: (err) => {
        this.loaderService.hide();
      }, complete: () => {
        this.loaderService.hide();
      }
    })
  }

}
