<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="Logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="Logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li *ngFor="let item of navigationItems" class="nav-item" [class.dropdown]="item.hasSubMenu">
                            <a [routerLink]="item.hasSubMenu ? undefined : item.link" class="nav-link" 
                               [class.dropdown-toggle]="item.hasSubMenu" 
                               (click)="item.hasSubMenu ? $event.preventDefault() : null">
                               {{ item.title }}
                            </a>

                            <ul *ngIf="item.hasSubMenu" class="dropdown-menu">
                                <li *ngFor="let subItem of item.subMenu" class="nav-item">
                                    <a [routerLink]="subItem.link" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        {{ subItem.title }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <div class="side-nav">
                        <!-- <a routerLink="/appointment">Get Appointment</a> -->
                        <ng-container *ngIf="isLoggedIn; else loginLink">
                            <div class="profile-menu">
                                <button class="profile-button" id="profileMenuButton" aria-haspopup="true" aria-expanded="false">
                                    <span class="profile-name">{{ firstName }}</span>
                                </button>
                                <div class="dropdown-content">
                                    <a routerLink="/lawyer-profile">
                                        <i class="flaticon-user"></i> Profile
                                    </a>
                                    <a (click)="logout()">
                                        <i class="flaticon-logout"></i> Logout
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #loginLink>
                            <a routerLink="/login">Login</a>
                        </ng-template>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
