import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    // Split the string and take the first part (HH:mm:ss)
    const timeParts = value.split(':');
    if (timeParts.length < 3) return value; // Return original if not valid

    const hours = parseInt(timeParts[0], 10);
    const minutes = timeParts[1].padStart(2, '0');
    
    // Determine AM or PM suffix
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12; // Converts 0 to 12 for midnight

    return `${formattedHours}:${minutes} ${ampm}`;
  }

}
