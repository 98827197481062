import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Retrieve the access token from localStorage
    const accessToken = localStorage.getItem('accessToken');

    // URL's which does not need access token
    const excludedUrls = [
      'https://lawyers-dev-api.thelawyerss.com/api/v1/sign_in/cust',
      'https://lawyers-dev-api.thelawyerss.com/api/v1/sign_in/lawyer'
    ];

    // Check if the request URL matches any of the excluded URLs
    const isExcluded = excludedUrls.some(url => req.url.startsWith(url));

    // Clone the request to add the new header only if it's not excluded
    const authReq = accessToken && !isExcluded 
      ? req.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`
          }
        })
      : req;

     // Pass the cloned request to the next handler and handle errors
     return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check for 401 or 403 status codes
        if (error.status === 401) {
          // Redirect to the login page
          localStorage.clear();
          window.location.href = "/login"; //changed because of navbar not reflecting
          // this.router.navigate(['/login']);
        }
        if(error.status === 403){
          // Redirect to the un-authorized page
          localStorage.clear();
          this.router.navigate(['/un-authorized']);
        }
        return throwError(error);
      })
    );
  }
}
