<footer>
    <div class="newsletter-area" *ngIf="!auth">
        <div class="container">
            <img src="assets/img/home-one/newsletter.png" alt="Shape">
            <h2>Subscribe Newsletter</h2>

            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off">
                <button class="btn contact-btn" type="submit">Subscribe</button>
            </form>
        </div>
    </div>

    <div class="container">
        <div class="row" style="padding-top: 2rem;">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                        <p>Our Squad of Attorneys are always here to provide you the best and efficient service. Still, if you want to know more about us and our firm, you can connect us through social media.</p>
                        <ul>
                            <li><a href="https://www.youtube.com/" target="_blank"><i class="icofont-youtube-play"></i></a></li>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Services</h3>

                        <ul>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Family Law</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Education Law</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Civil Law</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Criminal Law</a></li>
                            <li><a routerLink="/practice-details"><i class="icofont-simple-right"></i> Business Law</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Home</a></li>
                            <li><a routerLink="about"><i class="icofont-simple-right"></i> About Us</a></li>
                            <li><a routerLink="blog"><i class="icofont-simple-right"></i> Blog</a></li>
                            <li><a routerLink="attorney"><i class="icofont-simple-right"></i> Team</a></li>
                            <li><a routerLink="testimonial"><i class="icofont-simple-right"></i> Testimonial</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Find Us</h3>

                        <ul>
                            <li><i class="icofont-location-pin"></i> 28/A Street, New York City</li>
                            <li><i class="icofont-ui-call"></i><a href="tel:+02140210012"> +0214 021 0012</a></li>
                            <li><i class="icofont-at"></i> <a href="mailto:hello#64;lufz.com"> hello&#64;lufz.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-sm-7 col-lg-6">
                    <div class="copyright-item">
                        <p>© Lufz is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                    </div>
                </div>

                <div class="col-sm-5 col-lg-6">
                    <div class="copyright-item copyright-right">
                        <a routerLink="/terms-conditions" target="_blank">Terms & Conditions</a> <span>-</span>
                        <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<style>
.padding-top-default {
    padding-top: 2rem !important;
}
</style>