import { Component } from '@angular/core';

@Component({
  selector: 'app-lawyer-queries',
  templateUrl: './lawyer-queries.component.html',
  styleUrls: ['./lawyer-queries.component.scss']
})
export class LawyerQueriesComponent {

}
