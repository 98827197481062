import { DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CalendarEvent, CalendarEventAction, CalendarView } from "angular-calendar";
import { endOfDay, endOfMonth, endOfWeek, isSameDay, isSameMonth, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import { Subject } from "rxjs";
import { LoaderService } from "src/app/service/loader.service";
import { MasterService } from "src/app/service/master.service";
declare var bootstrap: any; // Declare bootstrap for modal management

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [DatePipe]
})
export class CalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();

  events: CalendarEvent[] = [];

  modalData!: {
    action: string;
    event: CalendarEvent;
  };

  activeDayIsOpen: boolean = false;
  eventsForSelectedDay: CalendarEvent[] = [];

  CalendarView = CalendarView;

  lawyerMasterId: string = '';
  startDate: string = '';
  endDate: string = '';
  statuses: any[] = [
    {
      id: 39,
      text: 'Active'
    },
    {
      id: 40,
      text: 'Completed'
    },
    {
      id: 41,
      text: 'Cancelled'
    },
    {
      id: 42,
      text: 'Reschedule'
    },
    {
      id: 43,
      text: 'Pending'
    },
  ];
  selectedAppointmentStatus: string | null = null;
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" title="Update Appointment"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  constructor(private masterService: MasterService, private datePipe: DatePipe, private loaderService: LoaderService) {
    this.lawyerMasterId = localStorage.getItem('userMasterId');
  }

  refresh = new Subject<void>();
  ngOnInit(): void {
    this.setDateRange(this.viewDate); // Set initial start and end date
    this.loaderService.show();
    this.getCalendarData();
  }

  setDateRange(date: Date) {
    this.startDate = this.datePipe.transform(startOfMonth(date), 'yyyy-MM-dd')!;
    this.endDate = this.datePipe.transform(endOfMonth(date), 'yyyy-MM-dd')!;
  }

  getCalendarData(){
    const dates = this.getViewDateRange();
    this.startDate = this.datePipe.transform(dates.startDate, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(dates.endDate, 'yyyy-MM-dd');
    this.masterService.getLawyerCalendar(this.lawyerMasterId, this.startDate, this.endDate).subscribe({
      next: (res: any) => {
        if (res && res.isSuccess === true) {
          this.setCalenderData(res);
        }
      }, error: (error: any) => {
        console.log(error);
        this.loaderService.hide();
      }, complete: () => {
        this.loaderService.hide();
      }
    });
  }

  setCalenderData(res: any){
    this.events = res.data.filter(x => x.appointmentStartTime != null).map(element => {
      const date = element.timeSlot.split('T')[0]; // Extract the date from timeSlot
      const startTime = new Date(`${date}T${element.appointmentStartTime}`); // Combine date and officeStartTime
      const endTime = new Date(`${date}T${element.appointmentEndTime}`); // Combine date and officeEndTime

      const title = element.appointmentStatusRemarks;
      const color = element.appointmentStatusId === 39 ? '#28a745'    // Green for Active
       : element.appointmentStatusId === 40 ? '#007bff'   // Blue for Completed
       : element.appointmentStatusId === 41 ? '#dc3545'   // Red for Cancelled
       : element.appointmentStatusId === 42 ? '#ffc107'   // Yellow for Rescheduled
       : '#6c757d';                                      // Gray for Pending or Default

      return {
        start: startTime,
        end: endTime,
        title: title,
        id: element.officeLawyerAvailabilityId,
        meta: element, //data of appointment
        color: {
          primary: color,
          secondary: "#00FFFF"
        },
        actions: this.actions 
      };
    });
  }

  dayClicked({ day }: { day: any }): void {
    const date: Date = day.date;
    const events: CalendarEvent[] = day.events;

    // Check if the clicked day is within the current month view
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen) || // Close if the same day is clicked and already open
        events.length === 0 // Close if there are no events for the day
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true; // Open the day view
        this.eventsForSelectedDay = events; // Show events for the clicked day
      }
      this.viewDate = date; // Set viewDate to the clicked date
    }
  }

  eventClicked(event: CalendarEvent): void {
    this.handleEvent('Clicked', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    //set prev status value
    this.selectedAppointmentStatus = event.meta.appointmentStatusId;
    // Show modal programmatically
    const modal = new bootstrap.Modal(document.getElementById('eventModal'));
    modal.show();
  }

  onStatusChange(eventId: number, statusId: number): void {
    console.log('chaned');
    
    // Find the event by id and update its status
    const event = this.events.find(e => e.id === eventId);
    if (event) {
      const eventData = event.meta;
      console.log();
      
      // event.meta.appointmentStatusId = statusId;
      // updateAPI
      if (this.selectedAppointmentStatus && eventData.appointmentStatusId) {
        // update status of appointment
        this.loaderService.show();
        this.masterService.updateLawyerAppointment(eventData.lawyerAppointmentId, eventData.appointmentStatusId).subscribe({
          next: (res: any) => {
            // alert('Done')
            this.closeModal();
          }, error: (err: any) =>{
            alert(err.error.error.errors[0])            
            this.loaderService.hide();
            this.closeModal();
          }, complete: () => {
            this.loaderService.hide();
            this.getCalendarData();
          }
        })
      }
      console.log(`Event ${eventId} updated to status ${statusId}`);
    }
  }

  closeModal(): void {
    const modal = bootstrap.Modal.getInstance(document.getElementById('eventModal'));
    modal.hide();
  }

  setView(view: CalendarView): void {
    this.view = view;
    this.activeDayIsOpen = false; // Close any open days when switching views
    // this.setDateRange(this.viewDate); // Update date range based on the new view
    this.getCalendarData(); // Fetch calendar data for the new view
  }

  nextPrev(): void {
    this.activeDayIsOpen = false;
    // this.setDateRange(this.viewDate); // Update date range based on the new view
    this.getCalendarData(); // Fetch calendar data for the new view
  }

  getViewDateRange() {
    let startDate: Date;
    let endDate: Date;

    switch (this.view) {
      case CalendarView.Month:
        startDate = startOfMonth(this.viewDate);
        endDate = endOfMonth(this.viewDate);
        break;
      case CalendarView.Week:
        startDate = startOfWeek(this.viewDate, { weekStartsOn: 0 }); // 0 for Sunday, 1 for Monday, etc.
        endDate = endOfWeek(this.viewDate, { weekStartsOn: 0 });
        break;
      case CalendarView.Day:
        startDate = startOfDay(this.viewDate);
        endDate = endOfDay(this.viewDate);
        break;
      default:
        startDate = this.viewDate;
        endDate = this.viewDate;
    }

    return { startDate, endDate };
  }
  
}
