<div class="home-slider-area">
    <div class="home-slider owl-theme owl-carousel">
        <div class="slider-item slider-bg-one">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="slider-text">
                                    <h1>It’s Our Pride To Fight For Your Dream</h1>
                                    <p>FIGHTING FOR YOUR DREAM</p>
                                    <a routerLink="/">FREE CONSULTATION <i class="icofont-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="col-lg-7">
                                <div class="slider-shape">
                                    <img class="s-s-one" src="assets/img/home-two/home-slider/2.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item slider-bg-one">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="slider-text">
                                    <h1>Our attorney’s main goal is to challenge  for  your privileges</h1>
                                    <p>FIGHTING FOR YOUR DREAM</p>
                                    <a routerLink="/">FREE CONSULTATION <i class="icofont-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="col-lg-7">
                                <div class="slider-shape">
                                    <img class="s-s-two" src="assets/img/home-two/home-slider/3.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item slider-bg-one">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="slider-text">
                                    <h1>Our Attorney’s are Always Prepared to Oppose For You</h1>
                                    <p>FIGHTING FOR YOUR DREAM</p>
                                    <a routerLink="/">FREE CONSULTATION <i class="icofont-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="col-lg-7">
                                <div class="slider-shape">
                                    <img class="s-s-three" src="assets/img/home-two/home-slider/4.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="slider-bottom-area">
    <div class="container">
        <div class="row slider-bottom-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="banner-bottom">
                    <ul>
                        <li><i class="flaticon-leader"></i></li>
                        <li>
                            <span>100% Legal</span>
                            <p>Govt Approved</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="banner-bottom">
                    <ul>
                        <li><i class="flaticon-auction"></i></li>
                        <li>
                            <span>Trusted</span>
                            <p>99% Case Won</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="banner-bottom">
                    <ul>
                        <li><i class="flaticon-support"></i></li>
                        <li>
                            <span>Support</span>
                            <p>Full Time Support</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="help-area help-area-three pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-one/5.jpg" alt="Help">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>We Provide Professional Attorneys to Assist You</h2>
                        <p>We are qualified for the success of our client & as a professional law firm. So these professional attorneys are still here at your side with kindness, integrity, and loyalty to help with the worst moments of your life.</p>

                        <div class="help-inner-left">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Browse Our Website</li>
                                <li><i class="flaticon-checkmark"></i> Choose Services</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Quick Reply</li>
                                <li><i class="flaticon-checkmark"></i> Best Performances</li>
                            </ul>
                        </div>

                        <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div>

                        <a class="cmn-btn" routerLink="/practice">Learn More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape">
        </div>
    </div>
</div>

<section class="practice-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Our Legal Practices Areas</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-law"></i>
                    </div>
                    <h3>Civil Law</h3>
                    <p>Our attorneys are highly talented  and able to offer the highest services in the civil law field.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-family"></i>
                    </div>
                    <h3>Family Law</h3>
                    <p>Attorney of Our Squad is amazingly experienced to obtain the best result in every year.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-inheritance"></i>
                    </div>
                    <h3>Business Law</h3>
                    <p>You don't have to think about the business law until our attorneys are there to support you.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-mortarboard"></i>
                    </div>
                    <h3>Education Law</h3>
                    <p>Our Attorneys are exceptionally competent of their clients and inspire them to thrive.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-auction"></i>
                    </div>
                    <h3>Criminal Law</h3>
                    <p>We always offer the top attorneys to resolve challenging criminal cases.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Cyber Law</h3>
                    <p>Lyzo also provide   cyber attorneys who are smart while analyzing these cases.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="expertise-area expertise-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-title text-start">
                    <span>OUR EXPERTISE</span>
                    <h2>Law Firm Devoted To Our Clients</h2>
                </div>

               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Experience</h3>
                            <p>Team of Lyzo are exceptionally skilled, realize their client’s condition, help them to achieve their motive.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Skilled Attorney</h3>
                            <p>Our attorneys are creative and knowledgeable and support clients by finding numerous solutions to win.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Legal Proces</h3>
                            <p>We manage all valid papers and paperwork and continue to enter our path lawfully.</p>
                        </li>
                    </ul>
               </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-image">
                    <img src="assets/img/home-one/10.png" alt="Expertise">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>PORTFOLIO</span>
            <h2>Check Out Our Popular Case Studies.</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">
                    <div class="portfolio-inner">
                        <span>Crime</span>
                        <h3><a routerLink="case-study-details/">Critical Murder Case</a></h3>
                        <p>15 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">
                    <div class="portfolio-inner">
                        <span>Rights</span>
                        <h3><a routerLink="case-study-details/">Children Rights</a></h3>
                        <p>16 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">
                    <div class="portfolio-inner">
                        <span>Finance</span>
                        <h3><a routerLink="case-study-details/">Money Loundering</a></h3>
                        <p>17 Feb 2023</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <a class="cmn-btn" routerLink="/case-study">See More</a>
        </div>
    </div>
</section>

<section class="team-area">
    <div class="container">
        <div class="section-title">
            <span>TEAM MEMBER</span>
            <h2>Meet Our Expert Attorneys</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/1.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Jerry Hudson</a></h3>
                        <span>Family Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/2.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Juho Hudson</a></h3>
                        <span>Criminal Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/3.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Sarah Se</a></h3>
                        <span>Divorce Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/4.jpg" alt="Team">

                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Aikin Ward</a></h3>
                        <span>Business Consultant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-form pb-100">
    <div class="counter-area counter-area-two">
        <div class="container">
            <div class="row counter-bg">
                <div class="col-6 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-judge"></i>
                        <div class="counter-inner">
                            <h3 class="counter">18</h3>
                            <p>Attorneys</p>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-auction"></i>
                        <div class="counter-inner">
                            <h3><span class="counter">99</span>%</h3>
                            <p>Case Won</p>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-medal"></i>
                        <div class="counter-inner">
                            <h3><span class="counter">100</span>%</h3>
                            <p>Legal Way</p>
                        </div>
                    </div>
                </div>
                
                <div class="col-6 col-lg-3">
                    <div class="counter-item">
                        <i class="icofont-law-order"></i>
                        <div class="counter-inner">
                            <h3><span class="counter">10</span>K</h3>
                            <p>Happy Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <form id="contactForm">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control" placeholder="Your Full Name">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Case Description"></textarea>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="contact-btn">Submit Case</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<section class="blog-area pt-100">
    <div class="container">
        <div class="section-title">
            <span>BLOG</span>
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="blog-slider owl-theme owl-carousel">
            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3>
                        <a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3><ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>