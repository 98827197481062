import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  baseUrl: string = 'https://lawyers-dev-api.thelawyerss.com/api/v1';

  constructor(protected http: HttpClient) { }

  get<T>(endpoint: string, params?: any, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" + endpoint;
    return this.http.get<T>(apiUrl, { params });
  }

  post<T>(endpoint: string, body: any, headers?: HttpHeaders, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" + endpoint;
    return this.http.post<T>(apiUrl, body, { headers });
  }

  put<T>(endpoint: string, body: any, headers?: HttpHeaders, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" + endpoint;
    return this.http.put<T>(apiUrl, body, { headers });
  }

  delete<T>(endpoint: string, body?: any, url?: string): Observable<T> {
    const apiUrl = url ?? this.baseUrl + "/" + endpoint;
    return this.http.delete<T>(apiUrl, { body: body });
  }
  
  getMenu<T>(userMasterId: string): Observable<T> {
    return this.http.get<T>(`https://lawyers-dev-api.thelawyerss.com/api/menu/${userMasterId ?? 0}`);
  }

  getAppointments(lawyerMasterId: string, searchText: string, pageNo: number, pageSize: number, fromDate: string | null, toDate: string | null, status: number = 0) {
    let params = `?pageNo=${pageNo}&pageSize=${pageSize}`;

    if (searchText) params += `&searchText=${searchText}`;
    if (fromDate) params += `&fromDate=${fromDate}`;
    if (toDate) params += `&toDate=${toDate}`;
    if (status && status !== 0) params += `&appointmentStatusId=${status}`;
    return this.http.get(`${this.baseUrl}/lawyer/${lawyerMasterId}/appointments${params}`)
  }

  getLawyerCalendar(lawyerMasterId: string, startDate: string, endDate: string){
    return this.http.get(`${this.baseUrl}/lawyer/calendar/${lawyerMasterId}?calendarStartDate=${startDate}&calendarEndDate=${endDate}`);
  }

  updateLawyerAppointment(lawyerAppointmentId: string, newAppointmentStatusId: number, message: string = ''){
    return this.http.put(`${this.baseUrl}/lawyer/appointment_action/${lawyerAppointmentId}`, {
      appointmentStatusId: newAppointmentStatusId,
      remarks: message
    });
  }
}