<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <h5>Unauthorized Access</h5>
                    <p>You do not have permission to view this page.</p>
                    <span>It seems you are trying to access a resource that requires proper authorization.</span>                  
                    <a routerLink="/">Return to Home</a>
                </div>
            </div>
        </div>
    </div>
</section>