import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-consultation-request',
  templateUrl: './consultation-request.component.html',
  styleUrls: ['./consultation-request.component.scss']
})
export class ConsultationRequestComponent implements OnInit {
  form: FormGroup;
  dataSource: any[] = [];
  totalItems: number = 0;
  currentPage: number = 1;
  pageSizes: number[] = [5, 10, 20, 50, 100]; // Available page sizes
  totalPages: number = 1;
  loading: boolean = false;
  statuses: any[] = [
    {
      id: 39,
      text: 'Active'
    },
    {
      id: 40,
      text: 'Completed'
    },
    {
      id: 41,
      text: 'Cancelled'
    },
    {
      id: 42,
      text: 'Reschedule'
    },
    {
      id: 43,
      text: 'Pending'
    },
  ];
  // actionStatuses: string[] = ['Pending', 'Rejected', 'Active']; // Active to be changed with Accepted
  lawyerMasterId: string = '';

  constructor(private service: MasterService, private loaderService: LoaderService, private fb: FormBuilder) {
    this.lawyerMasterId = localStorage.getItem('userMasterId');
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadAppointments();
  }

  // Initialize form group
  initializeForm() {
    this.form = this.fb.group({
      selectedStatus: [0], // default to an empty string or 'All'
      fromDate: [''],
      toDate: [''],
      searchText: [''],
      pageSize: [this.pageSizes[0]] // default to first page size option
    });
  }
  onSearchText(event: any){
    if(event === ''){
      this.loadAppointments();
    }
  }

  loadAppointments(): void {
    if (this.loading) return;
    this.loading = true;
    this.loaderService.show();
    

    const { searchText, pageSize, selectedStatus, fromDate, toDate } = this.form.value;
    
    this.service.getAppointments(this.lawyerMasterId, searchText, this.currentPage, pageSize, fromDate, toDate, parseInt(selectedStatus))
      .subscribe({
        next: (res: any) => {
          if (res && res.isSuccess) {
            this.dataSource = res.data;
            this.totalItems = this.dataSource[0].totalRows;
            this.totalPages = Math.ceil(this.totalItems / pageSize);
          }
        },
        error: (err: any) => {
          console.error(err);
          this.loading = false;
          this.loaderService.hide();
        },
        complete: () => {
          this.loading = false;
          this.loaderService.hide();
        }
      });
  }

  filterAppointments(): void {
    this.currentPage = 1; // Reset to the first page when filtering
    this.dataSource = [];
    this.loadAppointments();
  }

  sortAppointments(column: string): void {
    this.dataSource.sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });
  }

  getPages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  goToPage(page: number): void {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.loadAppointments();
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadAppointments();
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadAppointments();
    }
  }

  onPageSizeChange(): void {
    this.currentPage = 1; // Reset to first page
    this.dataSource = []; // Clear existing data
    this.loadAppointments(); // Reload appointments with new page size
  }

  getStatusColor(status: string): string {
    switch (status) {
      case 'Pending': return 'orange';
      case 'Rejected': return 'red';
      case 'Accepted': return 'green';
      default: return 'black';
    }
  }
   // Added Pagination so removed lazyloading
  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200 && !this.loading) {
  //     this.loadAppointments();
  //   }
  // }

  selectedAppointmentId: string;
  newStatus: number | null = null;
  // message;

  onStatusChange(appointmentId: string, newStatus: number) {
    // TODO Need to store back data to revert on failed request
    this.selectedAppointmentId = appointmentId;
    this.newStatus = newStatus;
    if (this.selectedAppointmentId && this.newStatus) {
      // update status of appointment
      this.loaderService.show();
      this.service.updateLawyerAppointment(this.selectedAppointmentId, this.newStatus).subscribe({
        next: (res: any) => {
          alert('Done')
        }, error: (err: any) =>{
          alert(err.error.error.errors[0])
          this.selectedAppointmentId = '';
          this.newStatus = null;
          this.loaderService.hide();
        }, complete: () => {
          this.loaderService.hide();
        }
      })
    }
  }

   

}
