import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-question',
  templateUrl: './customer-question.component.html',
  styleUrls: ['./customer-question.component.scss']
})
export class CustomerQuestionComponent {

}
