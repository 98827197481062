<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Practices</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Practices</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="practice-area practice-area-three pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-law"></i>
                    </div>
                    <h3>Civil Law</h3>
                    <p>Our attorneys are highly trained and skilled in the Civil Law sector  to provide the top service</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-family"></i>
                    </div>
                    <h3>Family Law</h3>
                    <p>Attorney of Our Squad is tremendously skillful to acquire a positive outcome and honest</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-inheritance"></i>
                    </div>
                    <h3>Business Law</h3>
                    <p>You don’t need to worry about your business law till our Master’s Attorneys are here to help you</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-mortarboard"></i>
                    </div>
                    <h3>Education Law</h3>
                    <p>Need Attorneys for Educational law?. Then, here our Talented & Professionals Attorneys ready to serve you</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-auction"></i>
                    </div>
                    <h3>Criminal Law</h3>
                    <p>We provide the Pre-Eminent Attorneys to solve the tough Criminal cases to help you</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Cyber Law</h3>
                    <p>We proffer cyber specialists attorneys who are brilliant in determining these types of cases</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>
        </div>
    </div>
</section>