<div class="banner-area banner-area-two banner-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-item">
                            <div class="banner-left">
                                <h1>Our Attorneys Always Provide The Excellent Service</h1>
                                <p>Teams of  Intelligent & Professional Attorneys of Lyzo are always prepared to make you triumph. We always try our best to make our clients happy.</p>
                                <a routerLink="/">FREE CONSULTATION <i class="icofont-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-item">
                            <div class="banner-right">
                                <img src="assets/img/home-two/1.png" alt="Banner">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-judge"></i>
                    <div class="counter-inner">
                        <h3 class="counter">18</h3>
                        <p>Attorneys</p>
                    </div>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-auction"></i>
                    <div class="counter-inner">
                        <h3><span class="counter">99</span>%</h3>
                        <p>Case Won</p>
                    </div>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-medal"></i>
                    <div class="counter-inner">
                        <h3><span class="counter">100</span>%</h3>
                        <p>Legal Way</p>
                    </div>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-team"></i>
                    <div class="counter-inner">
                        <h3><span class="counter">10</span>K</h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="help-area help-area-two pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/5.jpg" alt="Help">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>We will Be Fortunate to Help You</h2>
                        <p>As Professional Law Firm we are certified to achieve our client’s Victory. And, to deal with the bad times of your, these proficient  Attorneys will always serve you with compassion, honesty, and respect. To make you win is the most consequential motive of us.</p>
                        <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <div class="help-inner-left">
                            <ul>
                                <li><i class="flaticon-checkmark"></i>
                                    Browse Our Website</li>
                                <li><i class="flaticon-checkmark"></i> Choose Services</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Quick Reply</li>
                                <li><i class="flaticon-checkmark"></i> Best Performances</li>
                            </ul>
                        </div>

                        <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div>

                        <h3>Barrister Babatunde Smithi</h3>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center help-wrap">
            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>We are Concerned About Your Rights</h2>
                        <p>Lyzo Law Firm's whole squad supports all its clients with a positive and intimidating mindset. The team would always give you high-quality support to ensure that you win.
                        </p>

                        <div class="help-inner-left">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> On Time Response</li>
                                <li><i class="flaticon-checkmark"></i> Legal Way</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Legal Services</li>
                                <li><i class="flaticon-checkmark"></i> Honest Work</li>
                            </ul>
                        </div>
                        <a class="cmn-btn" routerLink="/practice">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/6.jpg" alt="Help">
                </div>
            </div>
        </div>

        <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape">
        </div>
    </div>
</div>

<section class="practice-area practice-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Our Legal Practices Areas</h2>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-law"></i>
                    </div>
                    <h3>Civil Law</h3>
                    <p>Our attorneys are well experienced and able to offer the best support in the civil law area.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-family"></i>
                    </div>
                    <h3>Family Law</h3>
                    <p>Our attorneys are highly qualified and experienced enough to obtain a better consequence.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-inheritance"></i>
                    </div>
                    <h3>Business Law</h3>
                    <p>Till our attorneys are here to support you, you don't have to think about your business cases.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-mortarboard"></i>
                    </div>
                    <h3>Education Law</h3>
                    <p>Squads of Lyzo are extremely competent, consider their client’s condition & empower them to succeed.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-auction"></i>
                    </div>
                    <h3>Criminal Law</h3>
                    <p>We have the leading attorneys to resolve challenging criminal cases very carefully and sensitively.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Cyber Law</h3>
                    <p>We offer security expert attorneys who are excellent at evaluating these situations.</p>
                    <a routerLink="/practice-details">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="expertise-area expertise-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-title text-start">
                    <span>OUR EXPERTISE</span>
                    <h2>Law Firm Devoted To Our Clients</h2>
                </div>

               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Experience</h3>
                            <p>Lyzo teams are exceptionally skilled, acknowledge their client’s condition & help them to achieve their target.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Skilled Attorney</h3>
                            <p>Our attorneys are innovative and knowledgeable and help clients pursue a vast number of solutions to succeed.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Legal Proces</h3>
                            <p>We maintain all valid papers and paperwork and continue to meet our destination legally.</p>
                        </li>
                    </ul>
               </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-image">
                    <img src="assets/img/home-one/10.png" alt="Expertise">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>PORTFOLIO</span>
            <h2>Check Out Our Popular Case Studies.</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Crime</span>
                        <h3><a routerLink="/case-study-details">Critical Murder Case</a></h3>
                        <p>15 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Rights</span>
                        <h3><a routerLink="/case-study-details">Children Rights</a></h3>
                        <p>16 Feb 2023</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Finance</span>
                        <h3><a routerLink="/case-study-details">Money Loundering</a></h3>
                        <p>17 Feb 2023</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <a class="cmn-btn" routerLink="/case-study">See More</a>
        </div>
    </div>
</section>

<section class="team-area">
    <div class="container">
        <div class="section-title">
            <span>TEAM MEMBER</span>
            <h2>Meet Our Expert Attorneys</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/1.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Jerry Hudson</a></h3>
                        <span>Family Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/2.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Juho Hudson</a></h3>
                        <span>Criminal Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/3.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Sarah Se</a></h3>
                        <span>Divorce Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/4.jpg" alt="Team">

                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Aikin Ward</a></h3>
                        <span>Business Consultant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-form pb-100">
    <div class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                            <img src="assets/img/home-one/12.png" alt="Shape">
                        </div>
                        <h3>Location</h3>
                        <ul>
                            <li>2108-267 Road Quadra,Toronto, Victiria Canada</li>
                        </ul>
                        <a class="location-link" href="#" target="_blank">View on gogle map</a>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                            <img src="assets/img/home-one/12.png" alt="Shape">
                        </div>
                        <h3>Email</h3>
                        <ul>
                            <li><a href="mailto:hello#64;lufz.com">hello#64;lufz.com</a></li>
                            <li><a href="mailto:info#64;lufz.com">info#64;lufz.com</a></li>
                        </ul>
                        <a class="location-link" href="#" target="_blank">View on gogle map</a>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                            <img src="assets/img/home-one/12.png" alt="Shape">
                        </div>
                        <h3>Phone</h3>
                        <ul>
                            <li><a href="tel:+0755543332322">+07 5554 3332 322</a></li>
                            <li><a href="tel:+0555962435736">+05 5596 2435 736</a></li>
                        </ul>
                        <a class="location-link" href="#" target="_blank">View on gogle map</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <form id="contactForm">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control" placeholder="Your Full Name">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Case Description"></textarea>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="contact-btn">Submit Case</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<section class="blog-area pt-100">
    <div class="container">
        <div class="section-title">
            <span>BLOG</span>
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="blog-slider owl-theme owl-carousel">
            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3>
                        <a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3><ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2023</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>