import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';
import { LawyerDetails } from 'src/app/types/LawyerDetails';

@Component({
  selector: 'app-lawyer-profile',
  templateUrl: './lawyer-profile.component.html',
  styleUrls: ['./lawyer-profile.component.scss']
})
export class LawyerProfileComponent implements OnInit {
  isLoggedIn: boolean = false;
  userMasterId: string | null = null;

  constructor(private service: MasterService, private loaderService: LoaderService) {
    this.checkLoginStatus();
  }

  checkLoginStatus(): void {
    this.userMasterId = localStorage.getItem('userMasterId');
    this.isLoggedIn = !!this.userMasterId;
  }
  lawyerDetails: LawyerDetails;
  ngOnInit(): void {
    if(this.isLoggedIn){
      // fetch lawyer details
      this.loaderService.show();
      this.service.get(`lawyer/${this.userMasterId}/profile`).subscribe({
        next: (res: any) => {
          if(res && res.isSuccess == true){
            this.lawyerDetails = res.data;
          }else{
            console.log('lawyer data not found!!!');            
          }
        }, error: (err) => {
          this.loaderService.hide();
        }, complete: () => {
          this.loaderService.hide();
        }
      })
    }
  }

}
