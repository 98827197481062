<div class="container" style="margin-top: 10rem; margin-bottom: 10rem;">
  <div class="row">
    <div class="col-md-4">
      <div class="btn-group gap-2">
        <div
          class="btn btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="nextPrev()"
        >
          Previous
        </div>
        <div
          class="btn btn-outline-secondary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          Today
        </div>
        <div
          class="btn btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="nextPrev()"
        >
          Next
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center">
      <h4>{{ viewDate | date: 'fullDate' }}</h4>
    </div>
    <div class="col-md-4 text-end">
      <div class="btn-group gap-2">
        <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
          Month
        </div>
        <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
          Week
        </div>
        <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
          Day
        </div>
      </div>
    </div>
  </div>

  <br />

  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
      [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event)" [refresh]="refresh"
      (eventClicked)="eventClicked($event.event)"></mwl-calendar-month-view>

    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
      (eventClicked)="eventClicked($event.event)"></mwl-calendar-week-view>

    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
      (eventClicked)="eventClicked($event.event)"></mwl-calendar-day-view>
  </div>

  <!-- Show the events of the selected day in a list when the day is clicked -->
  <!--<div *ngIf="activeDayIsOpen && eventsForSelectedDay.length > 0">
    <h5>Events for {{ viewDate | date: 'fullDate' }}:</h5>
    <ul>
      <li *ngFor="let event of eventsForSelectedDay" (click)="eventClicked(event)" style="cursor: pointer;">
        {{ event.title }}
      </li>
    </ul>
  </div> -->
</div>
<!-- Modal -->
<div class="modal fade" id="eventModal" tabindex="-1" aria-labelledby="eventModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header justify-content-between">
        <h5 class="modal-title" id="eventModalLabel">Update Status</h5>
        <button type="button" class="close" style="background: none;
        outline: none;
        border: none;
        font-size: 30px;
        color: #b0b0b0;
        font-weight: 600;" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="statusSelect">Select Status</label>
          <select id="statusSelect" class="form-select" (change)="onStatusChange(modalData?.event.id, $event.target.value)">
            <option *ngFor="let status of statuses" [selected]="modalData?.event?.meta.appointmentStatusId == status.id" [value]="status.id">
              {{ status.text }}
            </option>
          </select>
        </div>
      </div>
      
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</div>
