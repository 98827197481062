import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/service/loader.service';
import { MasterService } from 'src/app/service/master.service';

@Component({
  selector: 'app-attorney-details',
  templateUrl: './attorney-details.component.html',
  styleUrls: ['./attorney-details.component.scss']
})
export class AttorneyDetailsComponent implements OnInit {
  isLoggedIn: boolean = false;
  userMasterId: string | null = null;

  constructor(private service: MasterService, private loaderService: LoaderService) {
    this.checkLoginStatus();
  }

  checkLoginStatus(): void {
    this.userMasterId = localStorage.getItem('userMasterId');
    this.isLoggedIn = !!this.userMasterId;
  }
  lawyerDetails: any;
  ngOnInit(): void {
    if(this.isLoggedIn){
      // fetch lawyer details
      // this.loaderService.show();
      // this.service.get(`lawyer/${this.userMasterId}/profile`).subscribe({
      //   next: (res) => {
      //     console.log(res);
          
      //   }, error: (err) => {
      //     this.loaderService.hide();
      //   }, complete: () => {
      //     this.loaderService.hide();
      //   }
      // })
    }
  }

}
